.modal-fade {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
}

.side-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    z-index: 51;
    padding: 15px;
}

.side-menu.mobile {
    width: calc(100vw - 50px);
}

.side-menu.right {
    right: 0;
}

.side-menu.left {
    left: 0;
}