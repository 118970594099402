.overflow-fade{
    position: relative;
}

.overflow-fade:after {
    position: absolute;
    bottom: 0; 
    margin-bottom: 0vw; 
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
        rgba(33,37,41,255) 5%, 
       rgba(255,255,255, 0) 20%
    );
    pointer-events: none; /* so the text is still selectable */
}