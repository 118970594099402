.transition {
    transition: all 250ms;
}

.highlight-on-hover:hover {
    transform: scale(1.02);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.8) !important;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.overlay:hover {
    background-color: rgba(255, 255, 255, 0.02);
}

.use-pointer {
    cursor: pointer;
}